import Deferred from '../helpers/Deferred'
import { traceCreators } from '../logger'
import DataStore from '../data/DataStore'

class DataProvider {
  constructor({ dataFetcher, appLogger, errorReporter }) {
    this.dataFetcher = dataFetcher
    this.dataStore = new DataStore()
    this.dataFetchingBulk = {}
    this.bulkDataFetching = Promise.resolve()
    this.errorReporter = errorReporter
    this.appLogger = appLogger
  }

  createBulkRequest(datasetFetchConfigs) {
    this.dataFetchingBulk = datasetFetchConfigs.reduce(
      (acc, { id, refresh }) =>
        refresh || !this.dataStore.hasDataset(id)
          ? { ...acc, [id]: Deferred() }
          : acc,
      {},
    )

    const dataRequestingDatasets = Object.entries(this.dataFetchingBulk).map(
      ([, { promise }]) => promise,
    )

    this.bulkDataFetching = dataRequestingDatasets.length
      ? Promise.all(dataRequestingDatasets).then(async datasetConfigs =>
          this.dataStore.updateStore(await this.fetchBulkData(datasetConfigs)),
        )
      : Promise.resolve()
  }

  async getDataFromBulk({
    datasetId,
    collectionId,
    filter,
    sort,
    length,
    includes,
  }) {
    if (this.dataFetchingBulk[datasetId]) {
      // TODO: reject and return cache data if we have it
      this.dataFetchingBulk[datasetId].resolve({
        datasetId,
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
      })

      await this.bulkDataFetching

      this.dataFetchingBulk = {}
    }

    return (
      this.dataStore.getData({
        datasetId,
        collectionId,
        includes,
      }) ||
      this.getData({
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
      })
    )
  }

  async getData({ collectionId, filter, sort, offset, length, includes }) {
    const data = await this.dataFetcher.fetchData({
      collectionId,
      filter,
      sort,
      offset,
      length,
      includes,
    })

    this.dataStore.updateCollectionData({ collectionId, data })

    return data
  }

  getRecordById({ collectionId, recordId, includes }) {
    //not used for now
    return this.dataStore.getRecord({
      collectionId,
      recordId,
      includes,
    })
  }

  async remove({ collectionId, recordId }) {
    return this.dataFetcher.remove({
      collectionId,
      recordId,
    })
  }

  async save({ collectionId, record, includeReferences }) {
    return this.dataFetcher.save({
      collectionId,
      record,
      includeReferences,
    })
  }

  setCollectionData(data) {
    if (data) {
      this.dataStore.updateCollectionData(data)
    }
  }

  setStore(store) {
    if (store) {
      this.dataStore.updateStore(store)
    }
  }

  getStore() {
    return this.dataStore.getStore()
  }

  //TODO: make private after linter fix
  async fetchBulkData(datasetConfigs) {
    const {
      recordsByCollection,
      //TODO: come up with better name for recordsInfoByDataset,
      //TODO: since it is just an ordered array acording to our internal dataset config.
      //TODO: server knows nothing about dataset id.
      recordsInfoByDataset: sortedRecordsInfo,
    } = await this.appLogger.traceAsync(
      // TODO: move to proxy!!
      traceCreators.fetchPrimaryInitialData(),
      () => this.dataFetcher.fetchInitialData(datasetConfigs),
    )

    const recordsInfoByDataset = sortedRecordsInfo.reduce(
      (acc, { itemIds = [], totalCount = 0, error }, index) => {
        const { datasetId } = datasetConfigs[index]

        if (error) {
          //TODO: now wixDataProxy wrapper automagically reports an error to sentry.
          //TODO: after FES migration or change wixDataProxy to normal wixData without any magic
          //TODO: we will need to send the error to sentry here explicitly
          this.errorReporter(
            `Failed to load data for dataset ${datasetId}`,
            error,
          )
        }

        acc[datasetId] = { itemIds, totalCount }

        return acc
      },
      {},
    )

    return {
      recordsByCollection,
      recordsInfoByDataset,
    }
  }
}

export default DataProvider
